import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as NotFoundSvg } from "../../../Assets/img/error.svg"

const NotFound = () => {
  return (
    <div className='not_found_n'>
        <div className='notfound_text_head_n'>
                <p className='notfound_title_n'>
                        Oops. you hit a snap.
                </p>
                <p className='notfound_desc_n'>
                        Please make sure you're logged it to contiue browsing
                </p>
        </div>
        <div  className='notfound_svg_n'>
                <NotFoundSvg  />
        </div>
        <div className='notfound_login_n'>
                <Link to='/login' className="submitBtn">Log In</Link>
        </div>
    </div>
  )
}

export default NotFound
