import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { HiChevronRight } from 'react-icons/hi2'

const FeatureDropDown = () => {
        const { t } = useTranslation()
  return (
    <div className='nav_desktop_dropdown_ne'>
                <div className='container'>
                        <div className='nav_grid_ne'>
                                <Link to='/explore#customer-service' className='nav_grid_item_ne'>
                                        <div className='title'>{ t('non_user_nav.explore_dropdown.one.title') }</div>
                                        <p>{ t('non_user_nav.explore_dropdown.one.text') }</p>
                                </Link>
                                <Link to='/explore#use-case' className='nav_grid_item_ne'>
                                        <div className='title'>{ t('non_user_nav.explore_dropdown.two.title') }</div>
                                        <p>{ t('non_user_nav.explore_dropdown.two.text') }</p>
                                </Link>
                                <Link to='/explore#use-case' className='nav_grid_item_ne'>
                                        <div className='title'>{ t('non_user_nav.explore_dropdown.three.title') }</div>
                                        <p>{ t('non_user_nav.explore_dropdown.three.text') } </p>
                                </Link>
                                <Link to='/explore#membership' className='nav_grid_item_ne'>
                                        <div className='title'>{ t('non_user_nav.explore_dropdown.four.title') }</div>
                                        <p>{ t('non_user_nav.explore_dropdown.four.text') }</p>
                                </Link>
                                <Link to='/explore#' className='nav_grid_item_ne'>
                                        <div className='title'>{ t('non_user_nav.explore_dropdown.five.title') }</div>
                                        <p>{ t('non_user_nav.explore_dropdown.five.text') }</p>
                                </Link>
                        </div>
                </div>
        </div>
  )
}

export default FeatureDropDown

export const FeatureDropDownCategory = ({ category_list }) => {
        const navigate = useNavigate()
  return (
    <div className='nav_desktop_dropdown_ne'>
                <div className='container'>
                        <div className='nav_grid_ne category'>
                                {
                                        category_list.map(cat => (
                                        <a key={cat.pk} onClick={()=> navigate('/ebooks?category='+ cat?.name, {state: {"dir": cat?.name}})}   className={`nav_grid_item_ne ${cat?.children.length > 0 ? "sub_down": ""}`}>
                                                <div className='title'><span>{ cat.name }</span>{ cat?.children.length > 0 && <span className='cat_dropdown_indicator'><span><HiChevronRight /></span></span> }</div>
                                                <div className='sub_down_category_n'>
                                                {
                                                        cat?.children.length > 0 && (
                                                                <div className='drop_cat_list'>
                                                                        {
                                                                        cat?.children?.map(child_cat => (
                                                                                <li key={child_cat } className='category_item'>
                                                                                        <a onClick={()=> navigate(`/ebooks?category=${cat?.name}-${child_cat}`, {state: {"dir": cat?.name}})} >{ child_cat }</a>
                                                                                </li>
                                                                        ))
                                                                        }
                                                                </div>
                                                        )

                                                }
                                                </div>

                                        </a>
                                        ))
                                }                                
                        </div>
                </div>
        </div>
  )
}