import React from 'react'
import { useNavigate } from 'react-router-dom'

const CarouselItem = ({ name, img, color }) => {
    const navigate = useNavigate()

    const onFilter = () => {
        navigate('/ebooks?q='+name)
    }
    return (
        <>
           <div onClick={cat =>onFilter(cat)} style={{background: color}} data-cat={name}  className="carosel" draggable={true}>
                <div className="wrapper">
                    <img className="categoryImage" src={img} alt={name} />
                </div>
                <p className='carouselText'>{name}</p>

            </div> 
        </>
    )
}

export default CarouselItem
