import React from 'react'
import googleLogo from './googleLogo.png'
import { GoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
const GoogleButton = ({ text }) => {

    const { t } = useTranslation()
    
    const responseGoogle = (res)=>{
        alert(t("oauth_invalid"))
    }
    const errorMessage = (res)=>{
        console.log(res);
    }
    return (
        <div onClick={responseGoogle} className='google_btn_child'>
            <GoogleLogin disabled onKeyDown={responseGoogle}
                        clientId="426100214455-v4e5qnbvmu74t24uh9tel0n6qklskv6q.apps.googleusercontent.com"
                        render={renderProps => (
                            <></>
                        )}
                        buttonText=""
                        // onSuccess={responseGoogle}
                        // onError={errorMessage}
                        // onFailure={responseGoogle}
                        // cookiePolicy={'single_host_origin'}
                    />
        </div>
    )
}

export default GoogleButton