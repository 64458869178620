import React from 'react';
import AboutUsSection from './AboutUsSection';
import './newAbout.css';
import AdvertisementSection from './AdvertisementSection';
import { useTranslation } from 'react-i18next';
import WhatWeDoSection from './WhatWeDoSection';
import SplashSection from './SplashSection';
import LocationSection from './location/LocationSection';
import TeamSection from './TeamSection';

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <div className='about_page'>
      <div className='landing_explore about_expolore'>
          <div className='about_container'>
            <h1>{ t('about_page.title') } <span>HooYia</span></h1><br />
            <p>{ t('about_page.desc') }<a href="https://hooyia.net/en/" className="s_more">See more</a></p>
          </div>
      </div>
      <WhatWeDoSection />
      {/* <SplashSection  /> */}
      <TeamSection/>
      <LocationSection />
      {/* <AdvertisementSection /> */}
      <AboutUsSection />
    </div>
  );
}

export default AboutPage;
