import React from 'react'
import {ReactComponent as Loading} from '../../../../Assets/img/loader.svg'

const LoadingPage = () => {
  return (
    <div className='loading_page_loader'>
        <Loading />
    </div>
  )
}

export default LoadingPage