import React, { useLayoutEffect, useState } from 'react'

const Pagination = ({ count, currentPage, handlePageCount }) => {
	const [maxPages, setMaxPages] = useState(count)
	let initial = maxPages >= 0 ? maxPages : 3
	let pages = Array(5).fill(0)

	useLayoutEffect(() => {
		validPages()
	}, [currentPage])

	const validPages = () => {
		setMaxPages(pages.length - currentPage  + 1)
	}
	return (
		<div className='pagination'>
			{
				pages.map((_, index) => (
					<div 
						className='pagination_count' 
						onClick={() => handlePageCount(currentPage + index)}
						key={index}
					> {currentPage + index}</div>
				))
			}
		</div>
	)
}

export default Pagination
