import React, { useEffect, useLayoutEffect, useState } from 'react'
import Carousel from 'react-elastic-carousel';
import CarouselItem from './CarouselItem';
import Romance from '../../../../Assets/img/landing/category/romans.jpg'
import Fiction from '../../../../Assets/img/landing/category/fiction.jpg'
import Art from '../../../../Assets/img/landing/category/art.jpg'
import Cook from '../../../../Assets/img/landing/category/cook.jpg'
import Middle from '../../../../Assets/img/landing/category/middle_addult.jpg'
import { useStats } from 'react-instantsearch';
// import Romance from '../../../../Assets/img/landing/category/'

const breakPoints = [
    { width: 1, itemsToShow: 1.1 },
    { width: 200, itemsToShow: 1.4 },
    { width: 270, itemsToShow: 1.8 },
    { width: 320, itemsToShow: 2.4, itemsToScroll: 2 },
    { width: 380, itemsToShow: 2.6},
    { width: 430, itemsToShow: 3 },
    { width: 450, itemsToShow: 3.4 },
    { width: 550, itemsToShow: 4 },
    { width: 600, itemsToShow: 5 }
  ];

const CategoryCarousel = ({ categories }) => {
    const [categoryList, setCategoryList] = useState(categories)
    const catPlugin = [
        {
            name: "Art",
            color: '#B1E9CD',
            img: Art
        },
        {
            name: "Biography",
            color: '#D7F4E4',
            img: Cook
        },
        {
            name: "Children",
            color: '#DBA48A',
            img: Cook
        },
        {
            name: "Classics",
            color: '#DBA48A',
            img: Art
        },
        {
            name: "Cooking",
            color: '#D7F4E4',
            img: Cook
        },
        {
            name: "Fiction",
            color: '#D26465',
            img: Fiction
        },
        {
            name: "History",
            color: '#E2D9EC',
            img: Romance
        },
        {
            name: "Middle Grade",
            color: '#FBD0B5',
            img: Middle
        },
        {
            name: "Mystery",
            color: '#F9E6BC',
            img: Fiction
        },
        {
            name: "Non-Fiction",
            color: '#D26465',
            img: Fiction
        },
    ]
    
    
    
    return (
        <div className='carouselContainer_category'>
            <Carousel 
                autoPlay infiniteLoop interval={1000} 
                showArrows={false}
                breakPoints={breakPoints}
                enableAutoPlay={true}
                className="carouselWrapper_category"
            >

            {
                catPlugin.map((cat) => (
                    <CarouselItem color={cat.color} name={cat.name} img={cat.img} key = {cat?.pk} draggable="false" />    
                ))
            }
            </Carousel>
        </div>
    )
}

export default CategoryCarousel
