import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import HooyiaDefault from '../../Assets/img/HooYia.png'

const LazyImage = ({ image }) => {
  return (
        <div className='imgContraint'>
                {
                        image 
                        ?
                        <LazyLoadImage
                                alt='new image'
                                effect="blur"
                                src={image} 
                                className='product_image'
                        />
                        :
                        <img src={HooyiaDefault} className='product_image' alt='hooyia replacement' />
                }
        </div>
  )
}

export default LazyImage
