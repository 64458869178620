import React, { useState } from 'react'
import './Authenticate.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// import { BsGift } from 'react-icons/bs'
import FacebookButton from './OAuth/FaceBookButton'
import GoogleButton from './OAuth/GoogleButton'
import { postRequest } from '../../../Resquest'
import {ReactComponent as ThreeDots} from '../../../Assets/Loaders/ThreeDots.svg'

const ChangePassword = () => {

	const [pwd, setPwd] = useState('');
	const [c_pwd, setC_pwd] = useState('');
	const [message, setMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false)

	const [queryParameters] = useSearchParams()
	const userId = queryParameters.get("user_id")
	const timestamp = queryParameters.get("timestamp")
	const signature = queryParameters.get("signature")

	const { t } = useTranslation()

	const navigate = useNavigate()

	const sendForm = async () => {
		const dataset = {
			"user_id": userId,
			"timestamp": timestamp,
			"signature": signature,
			"password": pwd
		}

		setIsLoading(true)
		const response = await postRequest('/auth/reset-password/', dataset)
		console.log(response)
		response.code === "ERR_BAD_REQUEST" ? alert(response.response.data.password.join()) :
			response?.statusText === "OK" ? navigate('/password-reset-success') : navigate('/password-reset-link-expired')
		setIsLoading(false)
	}

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (pwd !== c_pwd) {
			alert("The passwords do not match")
			clearForm()
		} else sendForm()
	}

	const clearForm = () => { setPwd(''); setC_pwd(''); }

	const alert = (msg) => {
		setMessage(msg)
	}

	return (
		<div className='auth_n'>
			<div className='authenticate'>
				<div className='authenticate__content'>
					<div className='authenticate__content_container'>
						<h3>Change password</h3>
						<div className='mobile-image'>
							{/* <img src={Mobile} alt='' /> */}
						</div>
						<form method="post" onSubmit={handleFormSubmit} autoComplete="off">
							<div className={`alert ${message ? 'showAlert' : ''}`}>{message}</div>

							<div className="inputField">
								<input type="password" name="password" onChange={e => setPwd(e.target.value)} value={pwd} required />
								<label className="label" htmlFor="password"><span className="labelContent">new password</span></label>
							</div>

							<div className="inputField">
								<input type="password" name="password" onChange={e => setC_pwd(e.target.value)} value={c_pwd} required />
								<label className="label" htmlFor="password"><span className="labelContent">confirm new password</span></label>
							</div>

							<div className="buttonWrapper">
								<button type="submit" className="submitBtn">
									<span className='buttonText'>Save password </span>
									{
										isLoading && <span className='loadingButton'><ThreeDots /></span>
									}
								</button>
							</div>
							
						</form>

					</div>
				</div>
			</div>
		</div>
	)
}

export default ChangePassword