import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import LazyImage from '../Addons/LazyImage'
import { BsStarFill } from 'react-icons/bs'

const Product = ({ product }) => {
	const [showTooltip, setShowTooltip] = useState(false)

	return (
		<Link key={product.slug} 
			to={`/ebooks/${product.slug}`} 
			className='home_grid_item'
			onMouseEnter={() => setShowTooltip(true)}
			onMouseLeave={() => setShowTooltip(false)}>
			<div className='imgWrapper'>
				<LazyImage image={product.cover} />
			</div>
			<div className='desc'>
				<p>
					<span className='price '>XAF {product.price}</span>
					<span className='price priceOld'>XAF {product.discount_percentage > 0 ? ((product?.discount_percentage / 100) * product.price ) + parseInt(product.price) : product.price}</span>
				</p>
				<p className='sold-rating'>
					<span className='sold'>3000+ sold</span>
					<span className='rating'>{product?.avg_rating.toFixed(1)}<span className='icon'><BsStarFill /></span></span>
				</p>
				<span className='top_selling_tag'>{product?.taget_list[0]}</span>
				<span className='top_selling_tag most_baught'>{product?.taget_list[0]}</span>
				<p className='discount_product_name'>
				{product?.name && (
					product.name.length > 37
					? `${product.name.slice(0, 37)}...`
					: product.name
				)}
				</p>
			</div>
			{showTooltip && (
				<div className="tooltip">{product?.name}</div>
			)}
		</Link>
	)
}

export default Product