import React, { useContext, useState } from 'react'
import axios from 'axios';


import './Authenticate.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GoogleLogin } from '@react-oauth/google';

import { BsGift } from 'react-icons/bs'
import FacebookButton from './OAuth/FaceBookButton'
import GoogleButton from './OAuth/GoogleButton'
import { auth } from '../../../Resquest'
import { UserContext } from '../../../Context/AuthContext'
import {ReactComponent as ThreeDots} from '../../../Assets/Loaders/ThreeDots.svg'
import LinkedInButton from './OAuth/LinkedInButton';
import { ReactComponent as LoginSvg } from '../../../Assets/img/login_svg.svg';
import googleLogo from './OAuth/googleLogo.png'
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import useLocalStorage from '../../lib/UseLocalStorage';

const Login = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [message, setMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [user, setUser] = useContext(UserContext);
	const [showPassword, setShowPassword] = useState(false)
	const [redirectUrl, setRedirectUrl] = useLocalStorage('redirectUrl') 

	const location = useLocation();
  	const queryParams = new URLSearchParams(location.search);
	const phoneVerified = queryParams.get('phone_verified');

	const navigate = useNavigate()
	const responseMessage = (response) => {
		console.log(response);
	};
	const errorMessage = (error) => {
		console.log(error);
	};

	const userData = {
		"email": email.replace(/\s/g, ''),
		"password": password
	}

	const sendForm = async () => {
		setIsLoading(true)
		const res  = await auth('/auth/login', userData)
		if(res?.status === 200) {
			saveUser(res.data)
		} 
		res.response.status === 406 && alert(t("loginPage.invalidCredential"))
		res.response.status === 401 && alert(t("loginPage.accountNotActive"))
		res.response.status === 404 && alert(t("loginPage.accountNotFound"))
		res.response?.data?.detail && alert(t("loginPage.invalidCredential"))
		res.code === "ERR_NETWORK" && alert(t("loginPage.networkError"))
		setIsLoading(false)
	}
	
	const saveUser = (userResponse) => {
		redirect()
		setUser(userResponse)
	}

	const redirect = () => {
		window.location.href = redirectUrl || '/'
		setRedirectUrl('/')
	}
  
	const handleFormSubmit = (e) => {
		e.preventDefault();
		sendForm()
	}

	const alert = (msg) => {
		setMessage(msg)
		console.log('showing alert...', msg);
	}

	const clearForm = () => {
		setEmail('')
		setPassword('')
	}
  
	const callResetPassword = () => {

	}

	const {t} = useTranslation()

	return (
		<div className='auth_n'>
			<div className='authenticate'>
				<div className='authenticate__content'>
					<div className='authenticate__content_container'>
						<h3>{ t("auth_title.login") }</h3>
						<div className='mobile-image'>
							{/* <img src={Mobile} alt='' /> */}
						</div>
						
						<form method="post" onSubmit={handleFormSubmit} autoComplete="off">
							{ phoneVerified === 'true' && <div className={`alert showAlert phoneMessage`}>{ t("signupPage.phoneVerifiedMessage") }</div> }
							<p style={{ marginBottom: '1rem'}}>{ t("loginPage.login_message") }</p>
							
							<div className={`alert ${message ? 'showAlert' : ''}`}>{message}</div>
							<div className="inputField">
								<input autoComplete="new-password" autoCorrect="off" autoCapitalize="off" spellCheck="false" readonly onFocus={e => e.target.removeAttribute('readonly')}  type="text" onChange={e => setEmail(e.target.value)} name="email or phone" value={email} required />
								<label className="label" htmlFor="email or phone"><span className="labelContent">{ t('signupPage.email_or_phone') }</span></label>
							</div>

							<div className="inputField">
								<input  autoComplete="new-password" autoCorrect="off" autoCapitalize="off" spellCheck="false"readonly  onFocus={e => e.target.removeAttribute('readonly')}    type={showPassword ? "text": "password"} name="password" onChange={e => setPassword(e.target.value)} value={password} required />
								<label className="label" htmlFor="password"><span className="labelContent">{ t('signupPage.password') }</span></label>
								<span onClick={() => setShowPassword(!showPassword)} className='show_password_icon'>{ showPassword ? <FaRegEyeSlash /> : <FaRegEye /> }</span>
							</div>

							<div className="buttonWrapper">
								<button type="submit" className="submitBtn">
									<span className='buttonText'>{ t('signupPage.login') } </span>
									{
									isLoading && <span className='loadingButton'><ThreeDots /></span>
									}
									
								</button>
							</div>
							<p>{ t('loginPage.have_an_account') }<Link to='/register' className='link'>{ t('loginPage.signup') }</Link></p>
							<p>{ t('loginPage.forgot_password') } <Link to='/forgot-password' className='link forgot_password_link_n'>{ t('loginPage.click_here') }</Link></p>
							<div className="buttonWrapper signupBtn">
								<p className="or">OR</p>
								<div className='other_autheticate'>
									<div className='authBtn  googleBtn'>
										<GoogleButton />
										<div className='google_placeholder'>
											<img className="oauthBtnImg" src={googleLogo} alt="google" /> 
										</div>
									</div>
									<div className='authBtn'>
										<FacebookButton />
									</div>
									<div className='authBtn'>
										<LinkedInButton />
									</div>
								</div>
							</div>
						</form>

					</div>
					<div className='signup_convince'>
						<div className='convince_item'>
							<div className='title'>
								<span className='iconRegister'> <BsGift /> </span>
								<span> Features </span>
							</div>
							<p>{ t('loginPage.comment_two') }</p>
						</div>
						<div className='convince_item'>
							<div className='svg_login login_page_specific'>
								<LoginSvg />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login
