import React from 'react'
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import linkedInImage from './linkedin-logo-png-1837.png'
import { useTranslation } from 'react-i18next';
const LinkedInButton = () => {
  const { t } = useTranslation()


    const { linkedInLogin } = useLinkedIn({
        clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
        redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
        onSuccess: (code) => {
          loadAlert()
        },
        onError: (error) => {
          loadAlert()
        },
      });

      const loadAlert = () => {
        alert(t("oauth_invalid"))
      }
  return (
    <img
      onClick={loadAlert}
      src={linkedInImage}
      alt="Sign in with Linked In"
    //   style={{ maxWidth: '180px', cursor: 'pointer' }}
      className='oauthBtnImg'
    />
  )
}

export default LinkedInButton