import React from 'react'
import { BsStarFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import Watch from '../../Assets/img/41pOKvNMdnL._SX355_BO1,204,203,200_.jpg'
import LazyImage from '../Addons/LazyImage'
import { useTranslation } from 'react-i18next'

const ProductGrid = ({ products, isloading }) => {
	let placeholder = new Array(13).fill(0)
	const { t } = useTranslation()
	return (
		<div className='home_grid'>
			{
				isloading ? 
				placeholder.map((i, index) => (
				<Link key={index} className='home_grid_item'>
					<div className='imgWrapper loading_skeleton'>
					</div>
					<div className='desc '>
						<p className='price loading_skeleton skeleton_text'></p>
						<p className='sold-rating loading_skeleton skeleton_rating'></p>
						<p className='price loading_skeleton skeleton_text'></p>
					</div>
				</Link>
				))
				:
				products.map(product => (
					<Ebook product={product} />
				))

			}
		</div>
	)
}

export default ProductGrid

export const Ebook = ({ product }) => {

	const { t } = useTranslation()
	return (
		<Link key={product.slug} to={`/ebooks/${product.slug}`} className='home_grid_item'>
			<div className='imgWrapper'>
				<LazyImage image={product?.cover} />
			</div>
			<div className='desc'>
				<p>
					{
						product?.price === '0.00'?
							<span className='price'>{ t("ebooks.free") }</span>
						:
						(
							<>
								<span className='price'>XAF {product.price}</span>
								{/* <span className='price'>XAF {product.discount_percentage > 0 ? (product?.discount_percentage/100) * product.price : product.price}</span> */}
								{/* <span className='price priceOld'>XAF {product.price}</span> */}
							</>
						)
					}
				</p>
				<p className='sold-rating'>
					<span className='sold'>0+ { t("ebooks.sold") }</span>
					<span className='rating'>{product?.avg_rating.toFixed(1)}<span className='icon'><BsStarFill /></span></span>
				</p>
				<p className='remove_mobile'>
					{
						product?.taget_list.length > 1 ? (
							<>
								<span className='top_selling_tag'>{ product?.taget_list[0] }</span>
								<span className='top_selling_tag most_baught'>{product?.taget_list[1]}</span>
							</>
						) : (
							<span className='top_selling_tag most_baught'>{product?.category}</span>
						)
					}
				</p>
				<p className='discount_product_name'>{product?.name}</p>
			</div>
		</Link>
	)
}