import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri'

import './Carousel.css'
import { useTranslation } from 'react-i18next';

const breakPoints = [
  { width: 1, itemsToShow: 2 },
  { width: 200, itemsToShow: 2.5 },
  { width: 240, itemsToShow: 2.9 },
  { width: 270, itemsToShow: 3.3 },
  { width: 320, itemsToShow: 3.7, itemsToScroll: 2 },
  { width: 380, itemsToShow: 4.0 },
  { width: 430, itemsToShow: 4.3 },
  { width: 450, itemsToShow: 4.7 },
  { width: 550, itemsToShow: 5.4 },
  { width: 600, itemsToShow: 6 }
];
const PopularReviews = () => {
  const { t } = useTranslation()
    const reviews = [
        {
            name: "laura jansen",
            color: '#B1E9CD',
            quote: t("testimonial.laura"),
            img: "https://images.unsplash.com/photo-1598052163236-4ec4140c4c49?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
          },
          {
            name: "Alex Turner",
            color: '#BFE9EB',
            quote: t("testimonial.alex"),
            img: "https://source.unsplash.com/41SKIzYcFh0"
          },
          {
            name: "Maxime Dupont",
            color: '#F9E6BC',
            quote: t("testimonial.maxim"),
            img: "https://source.unsplash.com/qcCPIhhdgTw"
          },
          {
            name: "Emily Chen",
            color: '#B1E9CD',
            quote: t("testimonial.emily"),
            img: "https://images.unsplash.com/photo-1597093218359-06440f36cb6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
          },
          {
            name: "David Garcia",
            color: '#FBD0B5',
            quote: t("testimonial.gracia"),
            img: "https://source.unsplash.com/41SKIzYcFh0"
        }
    ]
  
    
    return (
      <section className='carouselContainer'>
        <h2 className='landing_title_center'>{ t('landing.titles.pop_rem') }</h2>
        <Carousel
            autoPlay infiniteLoop interval={3500} 
            breakPoints={breakPoints}
            className="carouselWrapper"
            renderArrowPrev={(onClickHandler, hasPrev, label) =><></>
            }
            renderArrowNext={(onClickHandler, hasNext, label) =><> </>
            }
        >
            {
              reviews.map(review => (
                <div key={review.name} className='popular_review'>
                  <div className='popular_review_person_profile'>
                    <div className='imgWrapper' style={{backgroudColor: review.color}}s>
                      {/* <img src='{ review.img }' /> */}
                    </div>
                  </div>
                  <div className='popular_reviewText'>
                    <div className='quote_body'>
                      <span className='quote_icon' style={{color: review.color}} ><RiDoubleQuotesL /></span>
                      <p>{ review.quote }</p>
                      <h3 className='quote_review_name' >{review.name}</h3>
                      <span className='quote_icon' style={{color: review.color}}><RiDoubleQuotesR /></span>
                    </div>
                  </div>

                </div>
              ))
            }
          </Carousel>
      </section>
    )
}

export default PopularReviews



